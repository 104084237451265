import { create } from "zustand";
import { persist } from "zustand/middleware";
import { serverURL } from './constants/serverURL.js'
import { debounce } from 'lodash';


export const useLoadedStore = create((set)=> ({
    isLoaded: false,
    setIsLoaded: (isLoaded) => set({ isLoaded })
}))

const fetchInitialData = async () => {
    const token = localStorage.getItem('token');
    try {
        const response = await fetch(`${serverURL}/getrules`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (response.ok) {
            const data = await response.json();
            return data.FAQ_rules || [];
        } else if (response.status === 401) {
            localStorage.removeItem("token")
            alert('Invaild login informations. Please login again')
            window.location.reload();
        } else {
            throw new Error('Failed to fetch nodes');
        }
    } catch (error) {
        console.error('Error fetching initial nodes:', error);
        return [];
    }
};

export const useNodeStore = create(
    persist((set, get) => ({
    nodes: [],
    initialNodeIndex: null,
    fullNodeIndex: null,
    
    currentNodes: [],
    setCurrentNodes: (currentNodes) => set({ currentNodes }),

    loadInitialData: async () => {
        const data = await fetchInitialData();
        if (data.nodes && data.initialNodeIndex !== undefined) {
            set({ nodes: data.nodes, initialNodeIndex: data.initialNodeIndex });
        }
    },

    setNodes: (nodes) => set({ nodes }),

    setInitialNodeIndex: (index) => {
        const nodes = get().nodes;
        if (index < 0 ){
            index = 0;
        }else if (index >= nodes.length) {
            index = nodes.length-1;
        }
        set({ initialNodeIndex: index });
    },

    setFullNodeIndex: (index) => {
        set({ fullNodeIndex: index });
    },

    startOption: null,
    setStartOption: (startOption) => set({ startOption }),

    startConnect: (nodeIndex, optionIndex) => {
        get().setStartOption([nodeIndex, optionIndex]);
    },

    appendNode: (newNode) => {
        const { nodes, setNodes } = get();
        setNodes([...nodes, newNode])
    },

    setConnect: (nodeIndex) => {
        const { nodes, startOption, setNodes, setStartOption } = get();

        if (!startOption) return;

        const [startNodeIndex, startOptionIndex] = startOption;

        if (startNodeIndex === nodeIndex) return;

        const updatedNodes = [...nodes];
        const updatedNode = { ...updatedNodes[startNodeIndex] };
        const updatedOptions = [...updatedNode.options];
        updatedOptions[startOptionIndex] = {
            ...updatedOptions[startOptionIndex],
            connectedTo: nodeIndex,
        };
        updatedNode.options = updatedOptions;
        updatedNodes[startNodeIndex] = updatedNode;
        setNodes(updatedNodes);

        setStartOption(null);
    },

    removeConnect: (nodeIndex, optionIndex) => {
        const { nodes, setNodes } = get();
        const updatedNodes = [...nodes];
        const updatedNode = { ...updatedNodes[nodeIndex] };
        const updatedOptions = [...updatedNode.options];
        updatedOptions[optionIndex] = {
            ...updatedOptions[optionIndex],
            connectedTo: null,
        };
        updatedNode.options = updatedOptions;
        updatedNodes[nodeIndex] = updatedNode;
        setNodes(updatedNodes);
    },

    setOptionTitle: (nodeIndex, optionIndex, title) => {
        const { nodes, setNodes } = get();
        const updatedNodes = [...nodes];
        const updatedNode = { ...updatedNodes[nodeIndex] };
        const updatedOptions = [...updatedNode.options];
        updatedOptions[optionIndex] = {
            ...updatedOptions[optionIndex],
            title: title,
        };
        updatedNode.options = updatedOptions;
        updatedNodes[nodeIndex] = updatedNode;
        setNodes(updatedNodes);
    },

    setNodeTitle: (nodeIndex, title) => {
        const { nodes, setNodes } = get();
        const updatedNodes = [...nodes];
        updatedNodes[nodeIndex] = { ...updatedNodes[nodeIndex], title };
        setNodes(updatedNodes);
    },

    setNodeImageTitle: (nodeIndex, imageTitle) => {
        const { nodes, setNodes } = get();
        const updatedNodes = [...nodes];
        updatedNodes[nodeIndex] = { ...updatedNodes[nodeIndex], imageTitle };
        setNodes(updatedNodes);
    },

    setNodeMinimized: (nodeIndex, isMinimized) => {
        const { nodes, setNodes } = get();
        const updatedNodes = [...nodes];
        updatedNodes[nodeIndex] = { ...updatedNodes[nodeIndex], isMinimized };
        setNodes(updatedNodes);
    },

    setNodePosition: (nodeIndex, position) => {
        const { nodes, setNodes } = get();
        const updatedNodes = [...nodes];
        updatedNodes[nodeIndex] = { ...updatedNodes[nodeIndex], ...position };
        setNodes(updatedNodes);
    },

    appendOption: (nodeIndex) => {
        const { nodes, setNodes } = get();
        const updatedNodes = [...nodes];
        const updatedNode = { ...updatedNodes[nodeIndex] };
        const updatedOptions = [...updatedNode.options, { title: "", connectedTo: null }];
        updatedNode.options = updatedOptions;
        updatedNodes[nodeIndex] = updatedNode;
        setNodes(updatedNodes);
    },

    removeOption: (nodeIndex, optionIndex) => {
        const { nodes, setNodes } = get();
        const updatedNodes = [...nodes];
        const updatedNode = { ...updatedNodes[nodeIndex] };
        updatedNode.options = updatedNode.options.filter((_, index) => index !== optionIndex);
        updatedNodes[nodeIndex] = updatedNode;
        setNodes(updatedNodes);
    },

    removeNode: (nodeIndex) => {
        let { nodes, setNodes } = get();

        nodes = nodes.map((node, index) => {
            const updatedOptions = node.options?.map(option => {
                if (option.connectedTo === nodeIndex) {
                    return { ...option, connectedTo: null };
                } else if (option.connectedTo > nodeIndex) {
                    return { ...option, connectedTo: option.connectedTo - 1 };
                }
                return option;
            });
            return { ...node, options: updatedOptions };
        });

        nodes = nodes.filter((_, index) => index !== nodeIndex);
        setNodes(nodes);
    },
}),
{
    name: "node-storage", // 로컬 스토리지에 저장될 키
    getStorage: () => localStorage, // (옵션) 기본값은 localStorage
}));


const fileTreeEample = [
      {
          "name": "상담 내용을 불러오기 위해 새로고침 부탁드립니다.",
          "type": "text",
      },
]

const fetchInitialBoilerplates = async () => {
    const token = localStorage.getItem('token');
    try {
        const response = await fetch(`${serverURL}/getboilerplates`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (response.ok) {
            const data = await response.json();
            return data.FAQ_rules || [];
        } else if (response.status === 401) {
            localStorage.removeItem("token")
            alert('Invaild login informations. Please login again')
            window.location.reload();
        } else {
            throw new Error('Failed to fetch nodes');
        }
    } catch (error) {
        console.error('Error fetching initial nodes:', error);
        return [];
    }
};

export const useBoilerplateStore = create(
  persist(
    (set, get) => {
      const debouncedPostBoilerplate = debounce((boilerplates) => {
        // postBoilerplate(JSON.stringify({ ...boilerplates }))
        //   .then(() => {
        //     console.log("Saved successfully");
        //   })
        //   .catch((error) => {
        //     console.error("Error saving boilerplates:", error);
        //   });
      }, 2000);

      const callPostBoilerplate = (boilerplates) => {
        //   postBoilerplate(JSON.stringify({ ...boilerplates }))
        //     .then(() => {
        //       console.log("Saved successfully");
        //     })
        //     .catch((error) => {
        //       console.error("Error saving boilerplates:", error);
        //     });
        }

      return {
      boilerplates: fileTreeEample,
      copiedContents: null,
      copyContents: (contents) => {
        set({ copiedContents:contents });
      },

      loadInitialData: async () => {
          const data = await fetchInitialBoilerplates();
          if (data.boilerplates) {
              set({ boilerplates: data.boilerplates });
              return data.boilerplates
          }
      },

      setBoilerplates: (boilerplates) => {
        // 상태는 즉시 업데이트
        set({ boilerplates });

        // 디바운스된 postBoilerplate 호출
        debouncedPostBoilerplate(JSON.stringify(boilerplates));
      },
      
      toggleOpen: (path) =>
        set((state) => {
          const updateFolder = (items, currentPath) => {
            return items.map((item, index) => {
              if (item.type === 'folder') {
                if (path.join('.') === [...currentPath, index].join('.')) {
                  // 현재 클릭한 폴더의 isOpen 값을 토글
                  return { ...item, isOpen: !item.isOpen };
                } else if (item.contents) {
                  // 하위 폴더도 재귀적으로 탐색
                  return { ...item, contents: updateFolder(item.contents, [...currentPath, index]) };
                }
              }
              return item;
            });
          };
          return {
            boilerplates: updateFolder(state.boilerplates, []),
          };
        }),

      removeBoilerplate: (position) => {
        const boilerplates = [...get().boilerplates]; // 상태 복사
        let currentLevel = boilerplates;

        // position 배열을 순회하며 대상 항목 탐색
        for (let i = 0; i < position.length - 1; i++) {
          currentLevel = currentLevel[position[i]].contents;
        }

        // 마지막 요소 삭제
        currentLevel.splice(position[position.length - 1], 1);

        callPostBoilerplate(boilerplates);

        // 상태 업데이트
        set({ boilerplates });
      },
    }},
    {
      name: 'boilerplates-store', // 로컬 스토리지 키 이름
      getStorage: () => localStorage, // 기본적으로 로컬 스토리지 사용
    }
  )
);

const fetchInitialBoilerplatesEN = async () => {
    const token = localStorage.getItem('token');
    try {
        const response = await fetch(`${serverURL}/getboilerplates-en`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (response.ok) {
            const data = await response.json();
            return data.FAQ_rules || [];
        } else if (response.status === 401) {
            localStorage.removeItem("token")
            alert('Invaild login informations. Please login again')
            window.location.reload();
        } else {
            throw new Error('Failed to fetch nodes');
        }
    } catch (error) {
        console.error('Error fetching initial nodes:', error);
        return [];
    }
};

export const useENBoilerplateStore = create(
  persist(
    (set, get) => {
      const debouncedPostBoilerplate = debounce((boilerplates) => {
        // postBoilerplate(JSON.stringify({ ...boilerplates }))
        //   .then(() => {
        //     console.log("Saved successfully");
        //   })
        //   .catch((error) => {
        //     console.error("Error saving boilerplates:", error);
        //   });
      }, 2000);

      const callPostBoilerplate = (boilerplates) => {
        //   postBoilerplate(JSON.stringify({ ...boilerplates }))
        //     .then(() => {
        //       console.log("Saved successfully");
        //     })
        //     .catch((error) => {
        //       console.error("Error saving boilerplates:", error);
        //     });
        }

      return {
      boilerplates: fileTreeEample,
      copiedContents: null,
      copyContents: (contents) => {
        set({ copiedContents:contents });
      },

      loadInitialData: async () => {
          const data = await fetchInitialBoilerplatesEN();
          if (data.boilerplates) {
              set({ boilerplates: data.boilerplates });
              return data.boilerplates
          }
      },

      setBoilerplates: (boilerplates) => {
        // 상태는 즉시 업데이트
        set({ boilerplates });

        // 디바운스된 postBoilerplate 호출
        debouncedPostBoilerplate(JSON.stringify(boilerplates));
      },
      
      toggleOpen: (path) =>
        set((state) => {
          const updateFolder = (items, currentPath) => {
            return items.map((item, index) => {
              if (item.type === 'folder') {
                if (path.join('.') === [...currentPath, index].join('.')) {
                  // 현재 클릭한 폴더의 isOpen 값을 토글
                  return { ...item, isOpen: !item.isOpen };
                } else if (item.contents) {
                  // 하위 폴더도 재귀적으로 탐색
                  return { ...item, contents: updateFolder(item.contents, [...currentPath, index]) };
                }
              }
              return item;
            });
          };
          return {
            boilerplates: updateFolder(state.boilerplates, []),
          };
        }),

      removeBoilerplate: (position) => {
        const boilerplates = [...get().boilerplates]; // 상태 복사
        let currentLevel = boilerplates;

        // position 배열을 순회하며 대상 항목 탐색
        for (let i = 0; i < position.length - 1; i++) {
          currentLevel = currentLevel[position[i]].contents;
        }

        // 마지막 요소 삭제
        currentLevel.splice(position[position.length - 1], 1);

        callPostBoilerplate(boilerplates);

        // 상태 업데이트
        set({ boilerplates });
      },
    }},
    {
      name: 'en-boilerplates-store', // 로컬 스토리지 키 이름
      getStorage: () => localStorage, // 기본적으로 로컬 스토리지 사용
    }
  )
);