import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { ReactFlow, MiniMap, ReactFlowProvider, useReactFlow, useNodesState } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { useNodeStore } from './store.js';
import CustomRFNode from './CustomRFNode.js';
import './GraphEditor.css';
import { saveRulesToServer } from './functions/saveRules.js'
/// https://reactflow.dev/examples/interaction/drag-and-drop DND 예제

// Debounce 함수
function debounce(func, delay) {
    let timer;
    return function (...args) {
        clearTimeout(timer);
        timer = setTimeout(() => func.apply(this, args), delay);
    };
}

function GraphEditorRF2({ setIsEditGraph }) {
    const { nodes, setNodes, currentNodes, setCurrentNodes, appendNode, initialNodeIndex, setInitialNodeIndex, setNodePosition, fullNodeIndex } = useNodeStore();
    const [currentEdges, setCurrentEdges] = useState([]);

    const [nodesState, setNodesState, onNodesChange] = useNodesState(currentNodes);

    function findTopNodes(nodes) {
        // 모든 connectedTo ID를 수집
        const connectedIds = new Set(
          nodes.flatMap(node => node.options?.map(option => option.connectedTo))
        );
      
        // connectedTo에 포함되지 않은 노드 찾기
        const topNodes = []
        nodes?.map((node, nodeIndex) => !connectedIds.has(nodeIndex) && topNodes.push(nodeIndex) );
        
        return topNodes;
    }

    useEffect(() => {
        const tempNodes = [];
        const tempEdges = [];
        const visitedNodes = new Set();
        const topNodes = findTopNodes(nodes);
    
        function pushTempNodes(nodeIndex) {
            const node = nodes[nodeIndex]
            if (!node || visitedNodes.has(nodeIndex)) return;
            visitedNodes.add(nodeIndex);
    
            tempNodes.push({
                id: `${nodeIndex}`,
                type: 'customRF',
                position: { x: node.left, y: node.top },
                data: {
                    node: node,
                    nodeIndex: nodeIndex,
                    title: node.title,
                    options: node.options,
                    isMinimized: node.isMinimized,
                    imageTitle: node.imageTitle
                }
            });
    
            if (node.isMinimized === true) return;
    
            node.options?.forEach((option, optionIndex) => {
                if (option.connectedTo) {
                    pushTempNodes(option.connectedTo);
                    const handleId = String.fromCharCode(97 + optionIndex);
                    tempEdges.push({
                        id: `e${nodeIndex}-${optionIndex}-${option.connectedTo}`,
                        source: `${nodeIndex}`,
                        target: `${option.connectedTo}`,
                        label: option.title,
                        sourceHandle: handleId
                    });
                }
            });
        }
    
        if (fullNodeIndex !== null) {
            pushTempNodes(fullNodeIndex);
        } else {
            topNodes?.forEach(nodeIndex => {
                pushTempNodes(nodeIndex);
            });
        }
    
        console.log(nodes)
        setCurrentNodes(tempNodes);
        setCurrentEdges(tempEdges);
        setNodesState(tempNodes);
    }, [nodes, fullNodeIndex]);

    const handlePositionChange = useCallback(
        debounce((e) => {
            if (e[0].type === "position") {
                setNodePosition(e[0].id, {top: e[0].position.y, left: e[0].position.x});
            }
        }, 300),
        [] // 빈 배열이므로 debounce 함수는 한 번만 생성됨
    );

    const { screenToFlowPosition } = useReactFlow();

    const handleRightClick = (e) => {
        e.preventDefault();

        const canvasPosition = screenToFlowPosition({ x: e.clientX, y: e.clientY }); // 마우스 위치를 캔버스 위치로 변환
        
        const newNode = {
            top: canvasPosition.y,
            left: canvasPosition.x,
            title: ``,
            options: [{ title: "", connectedTo: null }, { title: "", connectedTo: null }]
        };

        appendNode(newNode);
    };

    const saveNodes = () => {
        const nodesString = JSON.stringify(nodes, null, 2);
        const blob = new Blob([nodesString], { type: 'application/json' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'QNA.json';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    const resetFileInput = () => {
        const fileInput = document.getElementById('fileInput');
        if (fileInput) {
            fileInput.value = '';
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (!file) return;
    
        const reader = new FileReader();
        reader.onload = (e) => {
            try {
                const jsonData = JSON.parse(e.target.result);
                setNodes(jsonData);
            } catch (error) {
                console.error("Invalid JSON file");
            } finally {
                resetFileInput();
            }
        };
        reader.readAsText(file);
    };

    const nodeTypes = useMemo(() => ({ customRF: CustomRFNode }), []);

    function MiniMapNode({ x, y }) {
        return <circle cx={x} cy={y} r="80" fill='blue'/>;
    }

    const handleClose = () => {
        const confirmSave = window.confirm("변경된 내용을 저장하시겠습니까? 저장 시 타인의 데이터를 덮어씌울 수 있습니다.");
        if (confirmSave) {
            setIsEditGraph(false)
            saveRulesToServer(nodes, initialNodeIndex);
        } else {
            setIsEditGraph(false)
        }
    }

    return (
        <div className="editor-wrap">
            <div className="editor-title-wrap">
                <div className="editor-title">FAQ Flow Chart</div>
                <div className="editor-btns-wrap">
                    <label>Initial Question: </label>
                    <input className="set-init-index" value={initialNodeIndex} type='number' onChange={(e)=> setInitialNodeIndex(e.target.value)}></input>
                    <div className="import-btn">
                        <input type="file" accept=".json" id="fileInput" onChange={handleFileChange} style={{ display: 'none' }} />
                        <label htmlFor="fileInput" className="file-select-button">
                            Import
                        </label>
                    </div>
                    <div className="save-btn" onClick={() => saveRulesToServer(nodes, initialNodeIndex)}>Save</div>
                    <div className="save-btn" onClick={() => saveNodes()}>Download</div>
                    <div className="close-btn" onClick={handleClose}>Close</div>
                </div>
            </div>
            <div style={{ width: '100vw', height: '100vh' }}>
                <ReactFlow nodes={nodesState} edges={currentEdges} onNodesChange={(e) => {onNodesChange(e); handlePositionChange(e)}} nodeTypes={nodeTypes} onPaneContextMenu={handleRightClick}>
                    <MiniMap pannable={true} zoomable={true} nodeStrokeWidth={3} zoomStep={1} nodeComponent={MiniMapNode} />
                </ReactFlow>
            </div>
        </div>
    );
}

// Wrapping the component with ReactFlowProvider
function GraphEditorRF2WithProvider(props) {
    return (
        <ReactFlowProvider>
            <GraphEditorRF2 {...props} />
        </ReactFlowProvider>
    );
}

export default GraphEditorRF2WithProvider;