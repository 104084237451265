import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.css';
import App from './App';
import TestApp from './TestApp'; // TestApp 컴포넌트 임포트
import TestAppEN from './TestAppEN'; // TestApp 컴포넌트 임포트
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <Routes>
      <Route path="/" element={<App />} /> {/* 기본 경로 */}
      <Route path="/test" element={<TestApp />} /> {/* "/test" 경로 */}
      <Route path="/test_en" element={<TestAppEN />} /> {/* "/test" 경로 */}
    </Routes>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
